import { utils } from "@/helpers/utils";
import { Options } from "@interface/index";
import { User } from "@model/index";

export default class UserClass {
  private _id!: User["id"];
  private _phoneNumber!: User["phoneNumber"];
  private _gpsLocationEnabled!: User["gpsLocationEnabled"];
  private _pushNotificationsEnabled!: User["pushNotificationsEnabled"];
  private _advertisingEnabled!: User["advertisingEnabled"];
  private _privacyPolicyAccepted!: User["privacyPolicyAccepted"];
  private _private!: User["private"];
  private _avatar!: User["avatar"];
  private _roles!: User["roles"];
  private _username!: User["username"];
  private _firstName!: User["firstName"];
  private _lastName!: User["lastName"];
  private _locale!: User["locale"];
  private _email!: User["email"];
  private _gender!: User["gender"];
  private _address!: User["address"];
  private _city!: User["city"];
  private _zipCode!: User["zipCode"];
  private _country!: User["country"];
  private _birthDate!: User["birthDate"];
  private _createdAt!: User["createdAt"];
  constructor(private sessionData: User, private options: Options = {}) {
    if (options?.serialize) {
      this.serialize();
    }
  }

  private serialize(): void {
    utils.api.hydrate(this, this.sessionData);
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get phoneNumber(): string {
    return this._phoneNumber;
  }

  set phoneNumber(value: string) {
    this._phoneNumber = value;
  }

  get gpsLocationEnabled(): boolean {
    return this._gpsLocationEnabled;
  }

  set gpsLocationEnabled(value: boolean) {
    this._gpsLocationEnabled = value;
  }

  get pushNotificationsEnabled(): boolean {
    return this._pushNotificationsEnabled;
  }

  set pushNotificationsEnabled(value: boolean) {
    this._pushNotificationsEnabled = value;
  }

  get advertisingEnabled(): boolean {
    return this._advertisingEnabled;
  }

  set advertisingEnabled(value: boolean) {
    this._advertisingEnabled = value;
  }

  get privacyPolicyAccepted(): boolean {
    return this._privacyPolicyAccepted;
  }

  set privacyPolicyAccepted(value: boolean) {
    this._privacyPolicyAccepted = value;
  }

  get private(): boolean {
    return this._private;
  }

  set private(value: boolean) {
    this._private = value;
  }

  get avatar(): string | undefined {
    return this._avatar && this._avatar?.contentUrl
      ? this._avatar.contentUrl
      : require("@image/user/default-avatar.svg");
  }

  set avatar(value: string | undefined) {
    this._avatar = { contentUrl: value };
  }

  get roles(): Array<string> {
    return this._roles;
  }

  set roles(value: Array<string>) {
    this._roles = value;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get locale(): string {
    return this._locale;
  }

  set locale(value: string) {
    this._locale = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get gender(): string {
    return this._gender;
  }

  set gender(value: string) {
    this._gender = value;
  }

  get address(): Array<string> {
    return this._address;
  }

  set address(value: Array<string>) {
    this._address = value;
  }

  get city(): string {
    return this._city;
  }

  set city(value: string) {
    this._city = value;
  }

  get zipCode(): number {
    return this._zipCode;
  }

  set zipCode(value: number) {
    this._zipCode = value;
  }

  get country(): string {
    return this._country;
  }

  set country(value: string) {
    this._country = value;
  }

  get birthDate(): Date {
    return this._birthDate;
  }

  set birthDate(value: Date) {
    this._birthDate = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }
}
