import { utils } from "@/helpers/utils";
import { Options } from "@interface/index";
import { Client } from "@model/index";
import { Club, ClubLogo, ClubPhoto, ClubService } from "@model/Club";

export default class ClubClass {
  private _id!: Club["id"];
  private _name!: Club["name"];
  private _socialNetworks!: Club["socialNetworks"];
  private _description!: Club["description"];
  private _latitude!: Club["latitude"];
  private _longitude!: Club["longitude"];
  private _locale!: Club["locale"];
  private _timezone!: Club["timezone"];
  private _currency!: Club["currency"];
  private _deepLink!: Club["deepLink"];
  private _promotionalCodeEnabled!: Club["promotionalCodeEnabled"];
  private _userClientWalletEnabled!: Club["userClientWalletEnabled"];
  private _timetables!: Club["timetables"];
  private _generalTermsUrl!: Club["generalTermsUrl"];
  private _internalRulesUrl!: Club["internalRulesUrl"];
  private _appSportTimetableTypes!: Club["appSportTimetableTypes"];
  private _services!: Club["services"];
  private _logo!: Club["logo"];
  private _photos!: Club["photos"];
  private _mainPhoto!: string;
  private _updatedAt!: Club["updatedAt"];
  private _address!: Club["address"];
  private _city!: Club["city"];
  private _district!: Club["district"];
  private _zipCode!: Club["zipCode"];
  private _country!: Club["country"];
  private _webSiteUrl!: Club["webSiteUrl"];
  private _stripeAccountReference!: Club["stripeAccountReference"];
  private _accessControlService!: Club["accessControlService"];
  private _activities!: Club["activities"];
  private _createdAt!: Club["createdAt"];

  constructor(private clubData: Club, private options: Options = {}) {
    if (options?.serialize) {
      this.serialize();
    }
  }

  private serialize(): void {
    utils.api.hydrate(this, this.clubData);
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get socialNetworks(): Array<string> | undefined {
    return this._socialNetworks;
  }

  set socialNetworks(value: Array<string> | undefined) {
    this._socialNetworks = value;
  }

  get description(): string | undefined {
    return this._description;
  }

  set description(value: string | undefined) {
    this._description = value;
  }

  get latitude(): number | undefined {
    return this._latitude;
  }

  set latitude(value: number | undefined) {
    this._latitude = value;
  }

  get longitude(): number | undefined {
    return this._longitude;
  }

  set longitude(value: number | undefined) {
    this._longitude = value;
  }

  get locale(): string {
    return this._locale;
  }

  set locale(value: string) {
    this._locale = value;
  }

  get timezone(): string {
    return this._timezone;
  }

  set timezone(value: string) {
    this._timezone = value;
  }

  set socialReason(value: string) {
    this._timezone = value;
  }

  get currency(): string {
    return this._currency;
  }

  set currency(value: string) {
    this._currency = value;
  }

  get deepLink(): string | undefined {
    return this._deepLink;
  }

  set deepLink(value: string | undefined) {
    this._deepLink = value;
  }

  get promotionalCodeEnabled(): boolean | undefined {
    return this._promotionalCodeEnabled;
  }

  set promotionalCodeEnabled(value: boolean | undefined) {
    this._promotionalCodeEnabled = value;
  }

  get userClientWalletEnabled(): boolean {
    return this._userClientWalletEnabled;
  }

  set userClientWalletEnabled(value: boolean) {
    this._userClientWalletEnabled = value;
  }

  get timetables(): Array<string> | undefined {
    return this._timetables;
  }

  set timetables(value: Array<string> | undefined) {
    this._timetables = value;
  }

  get generalTermsUrl(): string | undefined {
    return this._generalTermsUrl;
  }

  set generalTermsUrl(value: string | undefined) {
    this._generalTermsUrl = value;
  }

  get internalRulesUrl(): string | undefined {
    return this._internalRulesUrl;
  }

  set internalRulesUrl(value: string | undefined) {
    this._internalRulesUrl = value;
  }

  get appSportTimetableTypes(): Array<string> | undefined {
    return this._appSportTimetableTypes;
  }

  set appSportTimetableTypes(value: Array<string> | undefined) {
    this._appSportTimetableTypes = value;
  }

  get services(): Array<ClubService> {
    return this._services;
  }

  set services(value: Array<ClubService>) {
    this._services = value;
  }

  get photos(): Array<ClubPhoto> {
    return this._photos;
  }

  set photos(value: Array<ClubPhoto>) {
    this._photos = value;
  }

  get mainPhoto(): string | undefined {
    return this._photos.length > 0
      ? process.env.VUE_APP_API_BASE + this._photos[0].contentUrl
      : undefined;
  }

  set mainPhoto(value: string | undefined) {
    this._photos[0].contentUrl = value;
  }

  get updatedAt(): string | undefined {
    return this._updatedAt;
  }

  set updatedAt(value: string | undefined) {
    this._updatedAt = value;
  }

  get address(): Array<string> | undefined {
    return this._address;
  }

  set address(value: Array<string> | undefined) {
    this._address = value;
  }

  get city(): string | undefined {
    return this._city;
  }

  set city(value: string | undefined) {
    this._city = value;
  }

  get district(): string | undefined {
    return this._district;
  }

  set district(value: string | undefined) {
    this._district = value;
  }

  get zipCode(): string | undefined {
    return this._zipCode;
  }

  set zipCode(value: string | undefined) {
    this._zipCode = value;
  }

  get country(): string {
    return this._country;
  }

  set country(value: string) {
    this._country = value;
  }

  get webSiteUrl(): string | undefined {
    return this._webSiteUrl;
  }

  set webSiteUrl(value: string | undefined) {
    this._webSiteUrl = value;
  }

  get stripeAccountReference(): string | undefined {
    return this._stripeAccountReference;
  }

  set stripeAccountReference(value: string | undefined) {
    this._stripeAccountReference = value;
  }

  get accessControlService(): {
    checkinTypes?: Array<string>;
  } {
    return this._accessControlService;
  }

  set accessControlService(value: { checkinTypes?: Array<string> }) {
    this._accessControlService = value;
  }

  get activities(): [] {
    return this._activities;
  }

  set activities(value: []) {
    this._activities = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }
}
