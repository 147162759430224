import { App } from "@vue/runtime-core";
import { utils } from "@/helpers/utils";
export default {
  install: (app: App) => {
    const domains = [
      {
        prefix: "D",
        requireComponent: require.context("@global/", true, /\.vue$/),
      },
    ];

    for (const domain of domains) {
      domain.requireComponent.keys().forEach((fileName) => {
        const componentConfig = domain.requireComponent(fileName);
        const componentName = utils.string.capitalizeFirstLetter(
          utils.string.toCamelCase(
            fileName.replace(/^\.\//, "").replace(/\.\w+$/, "")
          )
        );

        app.component(
          `${domain.prefix}${componentName}`,
          componentConfig.default || componentConfig
        );
      });
    }
  },
};
