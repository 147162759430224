export default [
  {
    path: "/auth",
    component: () => import("@page/auth/Auth.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("@page/auth/Login.vue"),
      },
      {
        path: "password-recovery",
        name: "PasswordReset",
        component: () => import("@page/auth/PasswordRecovery.vue"),
      },
    ],
  },
];
