export interface FormValidator {
  isRequired(value: string): boolean;
  isEmail(value: string): boolean;
  isPasswordValid(value: string): boolean;
  isSameAs(value: string, otherValue: string): boolean;
}
export class FormValidator {
  private static readonly EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private static readonly passwordLength = 8;

  public static isRequired(value: string) {
    return value.trim().length > 0;
  }

  public static isEmail(value: string) {
    return this.EMAIL_REGEX.test(value);
  }

  public static isPasswordValid(value: string) {
    return value.length >= FormValidator.passwordLength;
  }

  public static isSameAs(value: string, otherValue: string) {
    return value === otherValue;
  }
}
