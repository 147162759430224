export enum ButtonType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SUCCESS = "success",
  DANGER = "danger",
}

export enum ButtonSize {
  small = "small",
  default = "default",
  large = "large",
}

export enum ButtonExpand {
  block = "block",
  full = "full",
}
