import axios, {
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from "axios";
import { HttpTypeConfig } from "./config";
import { handleResponseError } from "./httpClient";

let count = 0;
const httpPublicClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  headers: {
    "Content-Type": HttpTypeConfig.JSON_CONTENT_TYPE,
  },
});

export default httpPublicClient;

// Add a request interceptor
httpPublicClient.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    return config;
  },
  function (error: Error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
httpPublicClient.interceptors.response.use(
  function (resp: AxiosResponse) {
    count++;
    if (count === 1) {
      const v = resp.headers["vers"] || "default";

      if (v !== localStorage.getItem("vers") && resp.config.method == "get") {
        localStorage.clear();
        localStorage.setItem("vers", v);
        window.location.reload(); // For new version, simply reload on any get
      }
    } else {
      count = 999;
    }
    return Promise.resolve(resp);
  },
  function (error: AxiosError) {
    return handleResponseError(httpPublicClient, error);
  }
);
