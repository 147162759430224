import { AxiosRequestConfig } from "axios";
import { ServerApi } from "@api/httpClient";
import { HttpService } from "@api/services/http-service";
import {
  UserExist,
  ValidationCheck,
  User,
  Tokens,
  ValidationIntents,
} from "@model/index";
export class AuthService {
  public static userExist(
    email: string,
    controller = new AbortController(),
    api: ServerApi = ServerApi.PUBLIC,
    config?: AxiosRequestConfig
  ): Promise<UserExist> {
    return HttpService.post<Promise<UserExist>>(
      "/user-exists",
      {
        identifier: email,
        userType: "user_club",
      },
      controller,
      api,
      config
    ).then((resp) => resp.data as UserExist);
  }

  public static requestValidationIntents(
    email: string,
    whiteLabelId?: string,
    controller = new AbortController(),
    api: ServerApi = ServerApi.PUBLIC,
    config?: AxiosRequestConfig
  ): Promise<ValidationIntents> {
    return HttpService.post<Promise<ValidationIntents>>(
      "/validation-intents.json",
      {
        email,
        whiteLabel: whiteLabelId
          ? `/clubs/white-labels/${whiteLabelId}`
          : undefined,
        userType: "user_club",
      },
      controller,
      api,
      config
    ).then((resp) => resp.data as ValidationIntents);
  }

  public static processValidationCheck(
    email: string,
    validationCode: string,
    controller = new AbortController(),
    api: ServerApi = ServerApi.PUBLIC,
    config?: AxiosRequestConfig
  ): Promise<ValidationCheck> {
    return HttpService.post<Promise<ValidationCheck>>(
      "/validation-check",
      {
        email,
        validationCode,
        userType: "user_club",
      },
      controller,
      api,
      config
    ).then((resp) => resp.data as ValidationCheck);
  }

  public static login(
    username: string,
    password: string,
    controller = new AbortController(),
    api: ServerApi = ServerApi.PUBLIC,
    config?: AxiosRequestConfig
  ): Promise<Tokens> {
    return HttpService.post<Promise<Tokens>>(
      "/club_login_check",
      {
        username,
        password,
      },
      controller,
      api,
      config
    ).then((resp) => resp.data as Tokens);
  }

  public static resolveUserData(
    controller = new AbortController(),
    api: ServerApi = ServerApi.PRIVATE,
    config?: AxiosRequestConfig
  ): Promise<User> {
    return HttpService.get<Promise<User>>(`me`, controller, api, config).then(
      (resp) => resp.data as User
    );
  }

  public static editUserClientsPassword(
    id: string,
    password: string,
    token: string,
    controller = new AbortController(),
    api: ServerApi = ServerApi.PRIVATE,
    config?: AxiosRequestConfig
  ): Promise<User> {
    if (config) {
      config.headers = {
        "X-Confirmation-Token": token,
      };
    } else {
      config = {
        headers: {
          "X-Confirmation-Token": token,
        },
      };
    }
    return HttpService.put<Promise<User>>(
      `/user-clubs/${id}`,
      { password, plainPassword: password },
      controller,
      api,
      config
    ).then((resp) => resp.data as User);
  }
}
