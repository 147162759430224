import { utils } from "@/helpers/utils";
import { Options } from "@interface/index";
import { TimetableBlockPrice } from "@/helpers/models";

export default class TimetableBlockPriceClass {
  private _id!: TimetableBlockPrice["id"];
  private _name!: TimetableBlockPrice["name"];
  private _label!: TimetableBlockPrice["label"];
  private _mainParticipantRequired!: TimetableBlockPrice["mainParticipantRequired"];
  private _mainPhoto!: TimetableBlockPrice["mainPhoto"];

  constructor(
    private sessionData: TimetableBlockPrice,
    private options: Options = {}
  ) {
    if (options?.serialize) {
      this.serialize();
    }
  }

  private serialize(): void {
    utils.api.hydrate(this, this.sessionData);
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get label(): string | undefined {
    return this._label;
  }

  set label(value: string | undefined) {
    this._label = value;
  }

  get mainParticipantRequired(): boolean | undefined {
    return this._mainParticipantRequired;
  }

  set mainParticipantRequired(value: boolean | undefined) {
    this._mainParticipantRequired = value;
  }

  get mainPhoto(): TimetableBlockPrice["mainPhoto"] {
    return this._mainPhoto;
  }

  set mainPhoto(value: TimetableBlockPrice["mainPhoto"]) {
    this._mainPhoto = value;
  }
}
