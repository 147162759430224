import { ClubService } from "@service/index";
import { Module, ActionContext } from "vuex";
import { ClubState, InitializedOptions } from "./club.interface";
import { Club } from "@model/Club";
import { RootState } from "@store/rootState.interface";
import ClubClass from "@class/club/ClubClass";

const ClubModule: Module<ClubState, RootState> = {
  namespaced: true,
  actions: {
    initialized(
      context: ActionContext<ClubState, RootState>,
      options: InitializedOptions
    ): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        ClubService.getClub(
          options.clubId,
          options.controller,
          options.api,
          options.config
        )
          .then((response: { "hydra:member": Club }) => {
            context.commit("setClub", response);
            resolve();
          })
          .catch((error: Error) => reject(error));
      });
    },
  },
  state: {
    club: null,
  },
  getters: {
    getClub: (state: ClubState) => state.club,
  },
  mutations: {
    setClub(state: ClubState, response: Club) {
      state.club = new ClubClass(response, { serialize: true });
    },
  },
};

export default ClubModule;
