import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "button-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
    class: _normalizeClass('button button-' + _ctx.theme),
    type: _ctx.type,
    size: _ctx.size,
    disabled: _ctx.loader,
    expand: _ctx.expand
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.icon && !_ctx.loader)
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 0,
              icon: _ctx.icon
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        (_ctx.loader)
          ? (_openBlock(), _createBlock(_component_ion_spinner, { key: 1 }))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["class", "type", "size", "disabled", "expand"]))
}