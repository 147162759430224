export interface StringUtils {
  capitalizeFirstLetter(str: string): string;
  toCamelCase(str: string): string;
  countWords(str: string): number;
  removeNonAlphaNumeric(str: string): string;
  truncate(str: string, length: number): string;
}

export class StringUtils {
  public static capitalizeFirstLetter(str: string): string {
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalized;
  }

  public static toCamelCase(str: string): string {
    const camelCased = str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toLowerCase() : word.toUpperCase()
      )
      .replace(/\s+/g, "");
    return camelCased;
  }

  public static countWords(str: string): number {
    const wordsCount = str.trim().split(/\s+/).length;
    return wordsCount;
  }

  public static removeNonAlphaNumeric(str: string): string {
    const alphanumericOnly = str.replace(/[^0-9a-zA-Z]/g, "");
    return alphanumericOnly;
  }

  public static truncate(str: string, length: number): string {
    const truncated = str.length > length ? str.slice(0, length) + "..." : str;
    return truncated;
  }
}
