import { toastController } from "@ionic/vue";
import { ToastType } from "@interface/toast";
export default class toast {
  public static async show(
    type: ToastType = ToastType.info,
    message: string,
    duration = 3000
  ) {
    const _toast = await toastController.create({
      message: message,
      duration: duration,
      position: "top",
      cssClass: "toast-wrap toast-" + type,
    });

    await _toast.present();
  }
}
