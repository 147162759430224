import { App } from "@vue/runtime-core";
import { IonicVue } from "@ionic/vue";

const appConfig = {
  //mode: "md",
};

export default {
  install: (app: App) => {
    app.use(IonicVue, appConfig);
  },
};
