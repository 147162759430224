import { StringUtils } from "@helper/string/string";
import { FormValidator } from "@helper/validate/validate";
import { Api } from "@helper/api/api";

export class utils {
  public static string: StringUtils = StringUtils;
  public static validate: FormValidator = FormValidator;
  public static api: Api<unknown> = Api;
}

export interface Utils {
  string: StringUtils;
  validate: FormValidator;
  api: Api<unknown>;
}
