import { utils } from "@/helpers/utils";
import { Session } from "@model/Session";
import { ActivityType, Options } from "@interface/index";
import { Activity, Playground, TimetableBlockPrice } from "@model/index";

export default class SessionClass {
  private _id!: Session["id"];
  private _activity!: Session["activity"];
  private _activityType!: Session["activityType"];
  private _startAt!: Session["startAt"];
  private _name!: Session["name"];
  private _endAt!: Session["endAt"];
  private _canceled!: Session["canceled"];
  private _confirmed!: Session["confirmed"];
  private _cancellationType!: Session["cancellationType"];
  private _timetableBlockPrice!: Session["timetableBlockPrice"];
  private _comment!: Session["comment"];
  private _category!: Session["category"];
  private _client!: Session["client"];
  private _match!: Session["match"];
  private _consideredAsPaidAt!: Session["consideredAsPaidAt"];
  private _creationOrigin!: Session["creationOrigin"];
  private _fromRecurrence!: Session["fromRecurrence"];
  private _formattedNumber!: Session["formattedNumber"];
  private _fixedParticipantsCount!: Session["fixedParticipantsCount"];
  private _maxParticipantsCountLimit!: Session["maxParticipantsCountLimit"];
  private _presentParticipantsCount!: Session["presentParticipantsCount"];
  private _playgrounds!: Session["playgrounds"];
  private _createdAt!: Session["createdAt"];

  constructor(private sessionData: Session, private options: Options = {}) {
    if (options?.serialize) {
      this.serialize();
    }
  }

  private serialize(): void {
    utils.api.hydrate(this, this.sessionData);
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get activity(): Activity {
    return this._activity;
  }

  set activity(value: Activity) {
    this._activity = value;
  }

  get activityType(): ActivityType {
    return this._activityType;
  }

  set activityType(value: ActivityType) {
    this._activityType = value;
  }

  get startAt(): string {
    return this._startAt;
  }

  set startAt(value: string) {
    this._startAt = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get endAt(): string {
    return this._endAt;
  }

  set endAt(value: string) {
    this._endAt = value;
  }

  get canceled(): boolean {
    return this._canceled;
  }

  set canceled(value: boolean) {
    this._canceled = value;
  }

  get confirmed(): boolean {
    return this._confirmed;
  }

  set confirmed(value: boolean) {
    this._confirmed = value;
  }

  get cancellationType(): string {
    return this._cancellationType;
  }

  set cancellationType(value: string) {
    this._cancellationType = value;
  }

  get timetableBlockPrice(): TimetableBlockPrice {
    return this._timetableBlockPrice;
  }

  set timetableBlockPrice(value: TimetableBlockPrice) {
    this._timetableBlockPrice = value;
  }

  get comment(): string {
    return this._comment;
  }

  set comment(value: string) {
    this._comment = value;
  }

  get category(): string {
    return this._category;
  }

  set category(value: string) {
    this._category = value;
  }

  get client(): string {
    return this._client;
  }

  set client(value: string) {
    this._client = value;
  }

  get match(): string {
    return this._match;
  }

  set match(value: string) {
    this._match = value;
  }

  get consideredAsPaidAt(): string {
    return this._consideredAsPaidAt;
  }

  set consideredAsPaidAt(value: string) {
    this._consideredAsPaidAt = value;
  }

  get creationOrigin(): string {
    return this._creationOrigin;
  }

  set creationOrigin(value: string) {
    this._creationOrigin = value;
  }

  get fromRecurrence(): boolean {
    return this._fromRecurrence;
  }

  set fromRecurrence(value: boolean) {
    this._fromRecurrence = value;
  }

  get formattedNumber(): string {
    return this._formattedNumber;
  }

  set formattedNumber(value: string) {
    this._formattedNumber = value;
  }

  get fixedParticipantsCount(): number {
    return this._fixedParticipantsCount;
  }

  set fixedParticipantsCount(value: number) {
    this._fixedParticipantsCount = value;
  }

  get maxParticipantsCountLimit(): number {
    return this._maxParticipantsCountLimit;
  }

  set maxParticipantsCountLimit(value: number) {
    this._maxParticipantsCountLimit = value;
  }

  get presentParticipantsCount(): number {
    return this._presentParticipantsCount;
  }

  set presentParticipantsCount(value: number) {
    this._presentParticipantsCount = value;
  }

  get playgrounds(): Array<Playground> {
    return this._playgrounds;
  }

  set playgrounds(value: Array<Playground>) {
    this._playgrounds = value;
  }

  get playground(): Playground {
    return this._playgrounds[0];
  }

  set playground(value: Playground) {
    this._playgrounds[0] = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }
}
