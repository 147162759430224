import axios from "axios";
import { AuthService } from "@service/auth/auth.service";
import { Module, Commit, Dispatch, ActionContext } from "vuex";
import {
  AuthState,
  LoginOptions,
  UserExistOptions,
  RequestValidationIntentsOptions,
  ProcessValidationCheckOptions,
  EditUserClientsPasswordOptions,
} from "./auth.interface";
import {
  UserExist,
  ValidationCheck,
  User,
  Tokens,
  ValidationIntents,
} from "@model/index";
import UserClass from "@class/user/UserClass";
import { RootState } from "@store/rootState.interface";
import { ApiOptions } from "@api/httpClient";

const AuthModule: Module<AuthState, RootState> = {
  namespaced: true,
  actions: {
    userExist(
      context: ActionContext<AuthState, RootState>,
      options: UserExistOptions
    ): Promise<UserExist> {
      return new Promise((resolve, reject) => {
        AuthService.userExist(
          options.email,
          options.controller,
          options.api,
          options.config
        )
          .then((response) => resolve(response))
          .catch((error: Error) => reject(error));
      });
    },
    requestValidationIntents(
      context: ActionContext<AuthState, RootState>,
      options: RequestValidationIntentsOptions
    ): Promise<ValidationIntents> {
      return new Promise((resolve, reject) => {
        AuthService.requestValidationIntents(
          options.email,
          options.whiteLabelId,
          options.controller,
          options.api,
          options.config
        )
          .then((response) => resolve(response))
          .catch((error: Error) => reject(error));
      });
    },
    processValidationCheck(
      context: ActionContext<AuthState, RootState>,
      options: ProcessValidationCheckOptions
    ): Promise<ValidationCheck> {
      return new Promise((resolve, reject) => {
        AuthService.processValidationCheck(
          options.email,
          options.validationCode,
          options.controller,
          options.api,
          options.config
        )
          .then((response) => resolve(response))
          .catch((error: Error) => reject(error));
      });
    },
    login(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      options: LoginOptions
    ): Promise<User> {
      return new Promise((resolve, reject) => {
        AuthService.login(
          options.username,
          options.password,
          options.controller,
          options.api,
          options.config
        )
          .then((response: Tokens) => {
            try {
              commit("login", response);
              dispatch("clientData", options)
                .then((user: User) => {
                  resolve(user);
                })
                .catch((error) => reject(error));
            } catch (e) {
              reject(e);
            }
            return response;
          })
          .catch((error: Error) => reject(error));
      });
    },
    clientData(
      { commit }: { commit: Commit },
      options: ApiOptions
    ): Promise<User> {
      return new Promise((resolve, reject) => {
        AuthService.resolveUserData(
          options.controller,
          options.api,
          options.config
        )
          .then((response: User) => {
            commit("setUser", response);
            resolve(response);
          })
          .catch((error: Error) => reject(error));
      });
    },
    editUserClientsPassword(
      context: ActionContext<AuthState, RootState>,
      options: EditUserClientsPasswordOptions
    ): Promise<User> {
      return new Promise((resolve, reject) => {
        AuthService.editUserClientsPassword(
          options.id,
          options.password,
          options.validation.token,
          options.controller,
          options.api,
          options.config
        )
          .then((response: User) => {
            resolve(response);
          })
          .catch((error: Error) => reject(error));
      });
    },
  },
  state: {
    user: JSON.parse(String(localStorage.getItem("user"))),
    token: JSON.parse(String(localStorage.getItem("token"))),
    tokenRefresh: JSON.parse(String(localStorage.getItem("refresh_token"))),
  },
  getters: {
    getToken: (state: AuthState) => state.token,
    getTokenRefresh: (state: AuthState) => state.tokenRefresh,
    getUser: (state: AuthState) =>
      state.user ? new UserClass(state.user, { serialize: true }) : null,
  },
  mutations: {
    login(state: AuthState, payload: Tokens) {
      localStorage.setItem("token", JSON.stringify(payload.token));
      localStorage.setItem(
        "refresh_token",
        JSON.stringify(payload.refresh_token)
      );
      state.token = payload.token;
      state.tokenRefresh = payload.refresh_token;
    },
    setUser(state: AuthState, payload: User) {
      localStorage.setItem("user", JSON.stringify(payload));
      state.user = payload;
    },
    logout(state: AuthState) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user");
      state.token = undefined;
      state.tokenRefresh = undefined;
      state.user = null;
      delete axios.defaults.headers.common["Authorization"];
    },
  },
};

export default AuthModule;
