import { App } from "@vue/runtime-core";
import { createI18n } from "vue-i18n";
import { DEFAULT_LOCALE, LOCALES } from "@constant/config";

const messages = {
  fr: require("@translation/fr.json"),
  en: require("@translation/en.json"),
  nl: require("@translation/nl.json"),
  de: require("@translation/de.json"),
  it: require("@translation/it.json"),
};

const localStorageLanguage = localStorage.getItem("language") || null;
const locale =
  localStorageLanguage !== null &&
  LOCALES.filter(
    (locale: { caption: string; id: string }) =>
      locale.id === localStorageLanguage
  ).length > 0
    ? localStorageLanguage
    : DEFAULT_LOCALE;

const i18n = createI18n({
  globalInjection: true,
  locale,
  allowComposition: true,
  messages,
});

export default {
  install: (app: App) => {
    app.use(i18n);
  },
  locale,
};
