export interface Api<T> {
  hydrate(model: T, data: Partial<T>, strict?: boolean): void;
  fromIriReferenceToId(ref: string, iriRef: string): string;
  fromIdToIriReference(ref: string, id: string): string;
}

export class Api<T> implements Api<T> {
  public static hydrate<T extends Record<string, unknown>>(
    model: T,
    data: Partial<T>,
    strict = false
  ): void {
    if (strict) {
      for (const key of Object.keys(model) as Array<keyof T>) {
        if (undefined !== data[key]) {
          model[key] = data[key] as T[keyof T];
        }
      }
    } else {
      for (const key in data) {
        model[key as keyof T] = data[key] as T[keyof T];
      }
    }
  }

  public static fromIriReferenceToId(ref: string, iriRef: string): string {
    return iriRef.split(ref)[1];
  }

  public static fromIdToIriReference(ref: string, id: string): string {
    return `${ref}/${id}`;
  }
}
