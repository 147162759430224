import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!

  return (_openBlock(), _createBlock(_component_ion_input, {
    name: _ctx.name,
    value: _ctx.value,
    label: _ctx.label,
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    ref: "input-text",
    type: _ctx.type,
    placeholder: _ctx.placeholder,
    required: _ctx.required,
    disabled: _ctx.disabled,
    maxlength: _ctx.maxlength,
    minlength: _ctx.minlength,
    readonly: _ctx.readonly,
    onKeyup: _cache[1] || (_cache[1] = ($event: any) => (this.$emit('input', $event)))
  }, null, 8, ["name", "value", "label", "modelValue", "type", "placeholder", "required", "disabled", "maxlength", "minlength", "readonly"]))
}