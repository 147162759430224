import { SESSION_STATUS } from "@constant/session";
export interface SessionStatusIndex {
  [key: string]: string;
}

export const sessionStatusIndex: SessionStatusIndex = {
  PASSED: SESSION_STATUS.PASSED,
  FUTURE: SESSION_STATUS.FUTURE,
  CANCELED: SESSION_STATUS.CANCELED,
};
