import { AxiosRequestConfig } from "axios";
import { ServerApi } from "@api/httpClient";
import { HttpService } from "@api/services/http-service";
import { Club } from "@model/Club";
export class ClubService {
  public static getClub(
    clubId: string,
    controller = new AbortController(),
    api: ServerApi = ServerApi.PUBLIC,
    config?: AxiosRequestConfig
  ): Promise<{ "hydra:member": Club }> {
    return HttpService.get<Promise<{ "hydra:member": Club }>>(
      `/clubs/${clubId}`,
      controller,
      api,
      config
    ).then((resp) => resp.data as { "hydra:member": Club });
  }
}
