import { utils } from "@/helpers/utils";
import { Options } from "@interface/index";
import { Participant } from "@model/index";
import ClientClass from "@class/client/ClientClass";

export default class ParticipantClass {
  private _id!: Participant["id"];
  private _booking!: Participant["booking"];
  private _bookingOwner!: Participant["bookingOwner"];
  private _client!: Participant["client"];
  private _canceled!: Participant["canceled"];
  private _confirmed!: Participant["confirmed"];
  private _createdAt!: Participant["createdAt"];
  private _updatedAt!: Participant["updatedAt"];
  private _clientEticket!: Participant["clientEticket"];
  private _category!: Participant["category"];
  private _canceledAt!: Participant["canceledAt"];
  private _canceledBy!: Participant["canceledBy"];
  private _canceledReason!: Participant["canceledReason"];
  private _canceledReasonComment!: Participant["canceledReasonComment"];
  private _addedBy!: Participant["addedBy"];
  private _accompanyingParticipants!: Participant["accompanyingParticipants"];
  private _accompanyingParticipantsCount!: Participant["accompanyingParticipantsCount"];
  private _capturablePaymentAmount!: Participant["capturablePaymentAmount"];

  constructor(private sessionData: Participant, private options: Options = {}) {
    if (options?.serialize) {
      this.serialize();
    }
  }

  private serialize(): void {
    utils.api.hydrate(this, this.sessionData);
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get booking(): string {
    return this._booking;
  }

  set booking(value: string) {
    this._booking = value;
  }

  get bookingOwner(): boolean {
    return this._bookingOwner;
  }

  set bookingOwner(value: boolean) {
    this._bookingOwner = value;
  }

  get client(): ClientClass {
    return this._client;
  }

  set client(value: ClientClass) {
    this._client = value;
  }

  get canceled(): boolean {
    return this._canceled;
  }

  set canceled(value: boolean) {
    this._canceled = value;
  }

  get confirmed(): boolean {
    return this._confirmed;
  }

  set confirmed(value: boolean) {
    this._confirmed = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }

  get updatedAt(): string {
    return this._updatedAt;
  }

  set updatedAt(value: string) {
    this._updatedAt = value;
  }

  get clientEticket(): string {
    return this._clientEticket;
  }

  set clientEticket(value: string) {
    this._clientEticket = value;
  }

  get category(): string {
    return this._category;
  }

  set category(value: string) {
    this._category = value;
  }

  get canceledAt(): string {
    return this._canceledAt;
  }

  set canceledAt(value: string) {
    this._canceledAt = value;
  }

  get canceledBy(): string {
    return this._canceledBy;
  }

  set canceledBy(value: string) {
    this._canceledBy = value;
  }

  get canceledReason(): string {
    return this._canceledReason;
  }

  set canceledReason(value: string) {
    this._canceledReason = value;
  }

  get canceledReasonComment(): string {
    return this._canceledReasonComment;
  }

  set canceledReasonComment(value: string) {
    this._canceledReasonComment = value;
  }

  get addedBy(): string {
    return this._addedBy;
  }

  set addedBy(value: string) {
    this._addedBy = value;
  }

  get accompanyingParticipants(): string[] {
    return this._accompanyingParticipants;
  }

  set accompanyingParticipants(value: string[]) {
    this._accompanyingParticipants = value;
  }

  get accompanyingParticipantsCount(): number {
    return this._accompanyingParticipantsCount;
  }

  set accompanyingParticipantsCount(value: number) {
    this._accompanyingParticipantsCount = value;
  }

  get capturablePaymentAmount(): number {
    return this._capturablePaymentAmount;
  }

  set capturablePaymentAmount(value: number) {
    this._capturablePaymentAmount = value;
  }
}
