import { createApp, App as AppType } from "vue";
import App from "./App.vue";
import "@ionic/vue/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
import plugins from "@plugin/index";

type PluginType = {
  [key: string]: {
    install: (app: AppType) => void;
  };
};

const app = createApp(App);

const pluginsTyped = plugins as PluginType;

for (const key in pluginsTyped) {
  if (Object.prototype.hasOwnProperty.call(pluginsTyped, key)) {
    const plugin = pluginsTyped[key];
    app.use(plugin);
  }
}

app.mount("#app");
