import ionic from "./ionic";
import vuex from "./vuex";
import router from "./router";
import component from "./component";
import utils from "./utils";
import i18n from "./i18n";
import toast from "./toast";
import moment from "./moment";

export default {
  ionic,
  vuex,
  router,
  component,
  utils,
  i18n,
  toast,
  moment,
};
