export const DEFAULT_LOCALE = "fr";
export const LOCALES = [
  {
    id: "en",
    caption: "English",
  },
  {
    id: "fr",
    caption: "French",
  },
  {
    id: "nl",
    caption: "Dutch",
  },
  {
    id: "de",
    caption: "German",
  },
];
