
import { Component, Prop, Vue } from "vue-facing-decorator";
import { ButtonType, ButtonSize, ButtonExpand } from "@interface/index";
import { IonButton, IonIcon, IonSpinner } from "@ionic/vue";
@Component({
  components: {
    IonButton,
    IonIcon,
    IonSpinner,
  },
  emits: ["click"],
})
export default class ButtonComponent extends Vue {
  @Prop({ type: Boolean, default: false }) loader!: boolean;
  @Prop({ type: String }) icon!: string;
  @Prop({ type: String, default: "button" }) type!: string;
  @Prop({
    type: String,
    default: "primary",
    validator: (value) =>
      Object.values(ButtonType).includes(value as ButtonType),
  })
  theme!: ButtonType;
  @Prop({ type: String, default: "default" }) size!: ButtonSize;
  @Prop({ type: String, default: "block" }) expand!: ButtonExpand;
}
