import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

const routes: Array<RouteRecordRaw> = [];

const routesImport = require.context("@router/", true, /\.ts$/);

routesImport.keys().forEach((fileName) => {
  const routeConfig = routesImport(fileName);
  routeConfig.default.forEach((route: RouteRecordRaw) => {
    routes.push({
      ...route,
    });
  });
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _from, next) => {
  const loggedIn = localStorage.getItem("token");

  if (
    to.path !== "/auth/login" &&
    to.path !== "/auth/password-recovery" &&
    !loggedIn
  ) {
    return next("/auth/login");
  } else if (
    (to.path === "/auth/login" || to.path === "/auth/password-recovery") &&
    loggedIn
  ) {
    return next("/");
  }

  next();
});

export default router;
