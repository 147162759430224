import { App } from "@vue/runtime-core";
import moment from "moment-timezone";
import i18n from "@plugin/i18n";

moment.locale(i18n.locale);
moment.tz("Europe/Paris");

export default {
  install: (app: App) => {
    app.config.globalProperties.$moment = moment;
  },
};
